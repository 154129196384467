import type { FC } from "react";

import { BookmarkIconAsButton } from "components";
import { BookmarkedAssignmentsNavigation } from "features";

interface Props {
  assignmentId: string;
  onNavigate: (assignmentId: string) => void;
  title: string;
}

const BookmarkedAssignmentDetailHeader: FC<Props> = ({ assignmentId, title, onNavigate }) => {
  return (
    <div className="assignment-detail-header">
      <div className="assignment-detail-header__title">
        <h1 className={"mb-0"}>{title}</h1>
      </div>
      <div className={"me-4px pe-2"}>
        <BookmarkIconAsButton userAssignmentId={assignmentId} />
      </div>
      <div className="border-start border-1 ps-2">
        <BookmarkedAssignmentsNavigation assignmentId={assignmentId} onNavigate={onNavigate} />
      </div>
    </div>
  );
};

export default BookmarkedAssignmentDetailHeader;
