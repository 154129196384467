import { useEffect, useRef, useState } from "react";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";

import { AnimatedCapybaraResult, OnboardingTooltip } from "components";
import { DotNavigation, ExerciseBar } from "features";

import { useAppSelector, useAppDispatch } from "store/hooks";
import { selectSessionCount } from "store/slices/user";
import { getAssignments, getResults, getSessionId, getSelectedTime } from "store/slices/exercise";
import { getGlobalTime, setIsTimerShown } from "store/slices/timer";

import { getAssignmentExerciseByType } from "utils/getAssignmentByType";
import { saveSessionToStorage } from "utils/sessionStorageHandler";
import { gtmTaskEvent } from "utils/gtmEvents";
import { logGeneralExerciseError } from "utils/processApiError";

import type { UserAssignmentForExerciseFragment } from "api/generated";
import { tooltipTexts } from "./TooltipTexts";

const setHighlightClass = (element: HTMLElement) => {
  element.classList.add("highlight-tooltip");
};

const resetHighlightClass = (element: HTMLElement) => {
  element.classList.remove("highlight-tooltip");
};

interface Props {
  evaluateAnswer?: boolean;
  isOnboarding?: boolean;
  onFinishingLastAssignment: () => void;
  onQuit: () => void;
}

const Test: FC<Props> = ({ evaluateAnswer = true, isOnboarding = false, onQuit, onFinishingLastAssignment }) => {
  const assignments = useAppSelector(getAssignments);
  const sessionId = useAppSelector(getSessionId);
  const results = useAppSelector(getResults);
  const spentTime = useAppSelector(getGlobalTime);
  const selectedTime = useAppSelector(getSelectedTime);
  const sessionsCount = useAppSelector(selectSessionCount);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [totalTime, setTotalTime] = useState(0);
  const [activeAssignment, setActiveAssignment] = useState<UserAssignmentForExerciseFragment | undefined>(undefined);
  const [shownTooltipIndex, setShownTooltipIndex] = useState<number | null>(null);

  const highlightedElement = useRef<HTMLElement | null>(null);
  const shownTooltips = useRef<number[]>([]);
  const processingTooltip = useRef<boolean>(false);

  useEffect(() => {
    if (typeof sessionId !== "string") {
      navigate("/cviceni");
    }
  }, [sessionId]);

  useEffect(() => {
    if (!isOnboarding && (typeof sessionsCount !== "number" || sessionsCount === 0)) showNextTooltip();
  }, []);

  useEffect(() => {
    if (typeof sessionId !== "string") return;

    saveSessionToStorage({
      sessionId,
      assignmentsData: assignments,
      assignmentsResults: results,
      spentTime,
      newEnvelopes: {},
      selectedTime,
    });
  }, [results]);

  useEffect(() => {
    let firstAssignmentIndex = Object.values(results).findIndex(result => result.status === "not_taken");

    if (firstAssignmentIndex === -1) {
      onFinishingLastAssignment();
      return;
    }

    if (firstAssignmentIndex !== 0) {
      const prevResult = Object.values(results)[firstAssignmentIndex - 1];

      if (prevResult !== undefined && prevResult.abilityScore === undefined) {
        firstAssignmentIndex--;
      }
    }

    const totalTime = assignments.reduce((accumulator, assignment) => {
      const timeToLearn = assignment.timeToLearn ?? 0;
      const timeToSolve = assignment.timeToSolve ?? 0;

      return accumulator + timeToLearn + timeToSolve;
    }, 0);

    const remainingTime = selectedTime === undefined ? totalTime : Math.min(totalTime, selectedTime * 60);
    setTotalTime(remainingTime);

    dispatch(setIsTimerShown(true));
    setActiveAssignment(assignments[firstAssignmentIndex]);
  }, [assignments]);

  const goNextAssignment = () => {
    if (activeAssignment === undefined) {
      logGeneralExerciseError(
        "No active assignment",
        undefined,
        assignments.map(assignment => assignment.id),
      );
      console.error("No active assignment");
      return;
    }

    const index = assignments.findIndex(assignment => assignment.id === activeAssignment.id);

    if (index === -1) {
      logGeneralExerciseError(
        "Active exercise not found in exercise list",
        activeAssignment.id,
        assignments.map(assignment => assignment.id),
      );
      navigate("/");
      return;
    }

    if (index === assignments.length - 1) {
      onFinishingLastAssignment();
      return;
    }

    setActiveAssignment(assignments[index + 1]);
    dispatch(setIsTimerShown(true));

    // Scroll to top on new task
    window.scrollTo({
      top: 0,
      behavior: "auto" as ScrollBehavior,
    });
  };

  useEffect(() => {
    if (activeAssignment !== undefined) {
      gtmTaskEvent("task-shown", {
        id: activeAssignment.id,
        subject: activeAssignment.subject?.appName,
        questionIndex:
          assignments.findIndex(item => {
            return item.id === activeAssignment.id;
          }) + 1,
      });
    }
  }, [activeAssignment]);

  const showNextTooltip = () => {
    if (
      processingTooltip.current ||
      highlightedElement.current !== null ||
      shownTooltips.current.length >= tooltipTexts.length
    )
      return;

    processingTooltip.current = true;

    for (let tooltipIndex = 0; tooltipIndex < tooltipTexts.length; tooltipIndex++) {
      if (shownTooltips.current.includes(tooltipIndex)) continue;

      const elementId = tooltipTexts[tooltipIndex]?.elementId;
      highlightedElement.current =
        elementId !== null && typeof elementId === "string" ? document.getElementById(elementId) : null;

      if (highlightedElement.current !== null && highlightedElement.current !== undefined) {
        shownTooltips.current.push(tooltipIndex);
        setHighlightClass(highlightedElement.current);
        setShownTooltipIndex(tooltipIndex);

        processingTooltip.current = false;

        return;
      }
    }

    processingTooltip.current = false;
  };

  const onTooltipClose = () => {
    if (highlightedElement.current !== null && highlightedElement.current !== undefined) {
      resetHighlightClass(highlightedElement.current);
    }

    highlightedElement.current = null;
    setShownTooltipIndex(null);
    showNextTooltip();
  };

  return (
    <div className="exercise">
      <OnboardingTooltip
        key={shownTooltipIndex}
        data={shownTooltipIndex === null ? null : tooltipTexts[shownTooltipIndex]}
        onConfirm={onTooltipClose}
        highlightedElement={highlightedElement.current ?? null}
      />

      <div className="exercise__header">
        <ExerciseBar
          totalTime={totalTime}
          isTimerStopped={false}
          userAssignmentId={activeAssignment?.id ?? ""}
          isProgressBarVisible={true}
          onQuit={onQuit}
        />
        <DotNavigation assignments={assignments} activeAssignmentId={activeAssignment?.id} results={results} />
      </div>

      {activeAssignment?.id !== undefined && results[activeAssignment?.id]?.status === "correct" && (
        <AnimatedCapybaraResult />
      )}

      <div className="exercise__body">
        {activeAssignment === undefined ? (
          /* TODO: translate */
          <div>Nastala chyba při zobrazování úlohy</div>
        ) : (
          getAssignmentExerciseByType(
            activeAssignment.assignment?.assignment?.assignmentType.type,
            activeAssignment,
            activeAssignment.id,
            goNextAssignment,
            evaluateAnswer,
          )
        )}
      </div>
    </div>
  );
};

export default Test;
