import { useEffect, useRef, useState } from "react";
import type { FC } from "react";

import { PracticeEvaluation } from "features";

import { useAppSelector, useAppDispatch } from "store/hooks";
import { getResults, clearSessionId, setNewEnvelopes } from "store/slices/exercise";

import { gtmExerciseSelfEvaluation } from "utils/gtmEvents";
import { processApiError } from "utils/processApiError";
import { removeSessionFromStorage } from "utils/sessionStorageHandler";

import { useSessionCloseMutation, useLazyGetAssignmentsEnvelopesQuery } from "api/generated";
import type { AssignmentCloseInput } from "api/generated";

interface Props {
  onSubmit: () => void;
}

const calculateAbilityScore = (values: number | Array<number | undefined> | undefined) => {
  if (values === undefined) return 0;
  if (typeof values === "number") return values;

  const filled = values.filter((value): value is number => typeof values === "number");
  if (filled.length === 0) return 0;

  const sum = filled.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);

  return sum / filled.length;
};

const Summary: FC<Props> = ({ onSubmit }) => {
  const dispatch = useAppDispatch();
  const [closeSession, { isLoading, error: apiMutationError }] = useSessionCloseMutation();
  const [getNewEnvelopes, { error: lazyApiError }] = useLazyGetAssignmentsEnvelopesQuery();

  const results = useAppSelector(getResults);

  const [mood, setMood] = useState<number>(0);
  const [moodText, setMoodText] = useState("");
  const loadingData = useRef(false);

  const getAssignmentsEnvelopes = async (ids: string[], assignmentEnvelopes: Record<string, string>) => {
    try {
      const response = await getNewEnvelopes({ assignments: ids });

      if ("data" in response) {
        const assignments = response.data?.userAssignments.items;

        if (assignments === undefined) return;

        for (const assignment of assignments) {
          if (typeof assignment.id !== "string" || typeof assignment.envelope !== "string") continue;

          assignmentEnvelopes[assignment.id] = assignment.envelope;
        }
      }
    } catch (error) {
      processApiError(error);
    }
  };

  const chunkArray = (array: string[]) => {
    const maxItemsInRequest = 30;

    const chunks = [];
    for (let i = 0; i < array.length; i += maxItemsInRequest) {
      chunks.push(array.slice(i, i + maxItemsInRequest));
    }
    return chunks;
  };

  const getEnvelopes = async () => {
    try {
      const assignmentEnvelopes: Record<string, string> = {};
      const chunks = chunkArray(Object.keys(results));

      const assignmentPromises = chunks.map(async ids => {
        await getAssignmentsEnvelopes(ids, assignmentEnvelopes).catch(console.error);
      });

      await Promise.all(assignmentPromises);

      dispatch(setNewEnvelopes(assignmentEnvelopes));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getEnvelopes().catch(console.error);
  }, [results]);

  useEffect(() => {
    if (lazyApiError === undefined) return;

    processApiError(lazyApiError);
  }, [lazyApiError]);

  useEffect(() => {
    if (apiMutationError === undefined) return;

    processApiError(apiMutationError);
  }, [apiMutationError]);

  const handleSubmitClick = () => {
    if (loadingData.current) return;
    loadingData.current = true;

    dispatch(clearSessionId());
    const additionalValues: AssignmentCloseInput[] = [];

    for (const [userAssignmentId, resultData] of Object.entries(results)) {
      additionalValues.push({
        userAssignmentId,
        timeLearnReal: resultData.timeLearn ?? 0,
        abilityScore: calculateAbilityScore(resultData.abilityScore),
        tip: resultData.tips.some(some => some),
      });
    }

    gtmExerciseSelfEvaluation(mood);

    closeSession({ mood: mood ?? 0, moodText, assignmentsClose: additionalValues })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        onSubmit();
        removeSessionFromStorage();
        loadingData.current = false;
      });
  };

  return (
    <PracticeEvaluation
      mood={mood}
      moodText={moodText}
      results={results}
      onMoodSelect={setMood}
      onMoodTextChange={setMoodText}
      onSubmit={handleSubmitClick}
      submitDisabled={isLoading}
    />
  );
};

export default Summary;
