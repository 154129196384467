import { useEffect, useRef, useState } from "react";
import { Accordion, Breadcrumb, Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import { Loading } from "components";
import IconArrowBack from "components/Icons/IconArrowBack/IconArrowBack";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { getFaqParams, setFaqQueryParams } from "store/slices/queryParams";

import { useGetFaqQuery } from "api/generated";

import { processApiError } from "utils/processApiError";

interface IFaq {
  title: string;
  description: string;
}

const Faq = () => {
  const { t } = useTranslation(["common"]);
  const { data, isLoading, error: apiError } = useGetFaqQuery();
  const faqSearch = useAppSelector(getFaqParams);
  const dispatch = useAppDispatch();

  const sortedFaqs = useRef<IFaq[]>([]);
  const [faqs, setFaqs] = useState<IFaq[]>([]);
  const [search, setSearch] = useState(faqSearch ?? "");

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    if (faqSearch === null) return;

    setSearch(faqSearch);
  }, [faqSearch]);

  useEffect(() => {
    if (data === undefined) return;

    const sortedData = data.faqAll
      .map(question => {
        return {
          title: question.title ?? "",
          description: question.description ?? "",
        };
      })
      .sort((a, b) => a.title.localeCompare(b.title))
      .filter(question => {
        if (search.length < 1) return question;

        return (
          question.title.toLowerCase().includes(search.toLowerCase()) ||
          question.description.toLowerCase().includes(search.toLowerCase())
        );
      });

    sortedFaqs.current = sortedData;
    setFaqs(sortedData);
  }, [data]);

  useEffect(() => {
    if (search.length < 1) {
      setFaqs(sortedFaqs.current);
      return;
    }

    const timer = setTimeout(() => {
      const searchedData = sortedFaqs.current.filter(question => {
        return (
          question.title.toLowerCase().includes(search.toLowerCase()) ||
          question.description.toLowerCase().includes(search.toLowerCase())
        );
      });

      setFaqs(searchedData);
    }, 250);
    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  const handleSearch = (searched: string) => {
    dispatch(setFaqQueryParams(searched));
    setSearch(searched);
  };

  return (
    <>
      <div className={"page-header"}>
        <Container className={"container-mw-md"}>
          <Breadcrumb>
            <li className={"breadcrumb-item"}>
              <Link to={"/"}>
                <IconArrowBack />
                {t("goHome")}
              </Link>
            </li>
          </Breadcrumb>

          <h1 className={"mb-8px"}>{t("pageNameHelp")}</h1>

          <Form>
            <Form.Control
              className={"form-search"}
              placeholder={t("search.placeholder", { ns: "help" })}
              value={search}
              onChange={e => {
                handleSearch(e.target.value);
              }}
            />
          </Form>
        </Container>
      </div>

      <Container
        className={`container-mw-md page-container pt-25px pt-sm-45px flex-grow-1 ${
          !isLoading && faqs.length === 0 ? "d-grid" : ""
        }`}
      >
        {isLoading ? (
          <Loading />
        ) : faqs.length === 0 ? (
          <p className={"mx-5 my-auto mt-sm-3 mb-sm-0 text-center"}>
            <Trans i18nKey={"common:searchNotFoundFaq"}>
              Promiň! 😌 Hledaný text se nám nepodařilo nalézt v žádné z našich často kladených otázek. Zkus prosím
              výraz přeformulovat či zkrátit. Pokud ani to nepomůže, napiš nám prosím na{" "}
              <a href={"mailto:ahoj@to-das.cz"}>ahoj@to-das.cz</a> – moc rádi Ti poradíme.
            </Trans>
          </p>
        ) : (
          <Accordion defaultActiveKey="0" className="my-sm-20px">
            {faqs.map((question, index) => {
              return (
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header>
                    <span dangerouslySetInnerHTML={{ __html: question.title ?? "" }}></span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <span dangerouslySetInnerHTML={{ __html: question.description ?? "" }}></span>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        )}
      </Container>
    </>
  );
};

export default Faq;
