import { useEffect, useState } from "react";
import type { ChangeEvent, FormEvent } from "react";
import { Button, Breadcrumb, Col, Container, Form, Image, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useGetSubjectsTopicsQuery, useLazySearchAssignmentsQuery, useLazyGetBookmarksCountQuery } from "api/generated";

import { AssignmentBox, EnvelopeExplanationModal, Loading, Pagination } from "components";
import type { IAssignment } from "components/AssignmentBox/AssignmentBox";
import IconArrowBack from "components/Icons/IconArrowBack/IconArrowBack";
import { SubjectsTab } from "features";

import { ReactComponent as IconBookmark } from "images/icons/bookmark.svg";

import { useAppSelector, useAppDispatch } from "store/hooks";
import { buyTestsCount, unlockTestsCount } from "store/slices/user";
import { setTopicAssignments } from "store/slices/assignmentNavigation";
import { setAssignmentsGlobalQueryParams } from "store/slices/queryParams";

import { sortGeneralSubjects } from "utils/sortTopics";
import { useQuery } from "utils/hooks/useQuery";
import { processApiError } from "utils/processApiError";

import { PAGE_QUERY_PARAM, SEARCH_QUERY_PARAM } from "const";

const getTopicHref = (topicId: string) => {
  return `/ulohy/${topicId}`;
};

const getAssignmentHref = (assignmentId: string) => {
  return `/ulohy/detail/${assignmentId}`;
};

const Subjects = () => {
  const { t } = useTranslation(["common", "assignments"]);
  const testsToBuy = useAppSelector(buyTestsCount);
  const testsToUnlock = useAppSelector(unlockTestsCount);

  const { data, isLoading, error: apiError } = useGetSubjectsTopicsQuery();
  const [trigger, { error: apiLazySearchError }] = useLazySearchAssignmentsQuery();
  const [getBookmarks, { error: apiLazyBookmarkError }] = useLazyGetBookmarksCountQuery();
  const { removeAllQueryParams, setEagerQueryParam, setSearchParam, searchParams } = useQuery();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isModalShown, showModal] = useState(false);
  const [bookmarksCount, setBookmarksCount] = useState(0);
  const [foundAssignments, setFoundAssignments] = useState<IAssignment[] | null>(null);
  const [searchText, setSearchText] = useState(searchParams.get(SEARCH_QUERY_PARAM));
  const [isSearching, setIsSearching] = useState(false);

  const pageString = searchParams.get(PAGE_QUERY_PARAM);
  const page = typeof pageString === "string" ? Number(pageString) : 1;

  useEffect(() => {
    getBookmarks()
      .then(({ data }) => {
        setBookmarksCount(data?.bookmarks.length ?? 0);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    if (apiLazySearchError === undefined) return;

    processApiError(apiLazySearchError);
  }, [apiLazySearchError]);

  useEffect(() => {
    if (apiLazyBookmarkError === undefined) return;

    processApiError(apiLazyBookmarkError);
  }, [apiLazyBookmarkError]);

  useEffect(() => {
    const searchParam = searchParams.get(SEARCH_QUERY_PARAM);
    const pageParam = searchParams.get(PAGE_QUERY_PARAM);

    dispatch(
      setAssignmentsGlobalQueryParams({
        ...(typeof searchParam === "string" ? { [SEARCH_QUERY_PARAM]: searchParam.toString() } : null),
        ...(typeof pageParam === "string" ? { [PAGE_QUERY_PARAM]: pageParam.toString() } : null),
      }),
    );
  }, [searchParams]);

  useEffect(() => {
    if (data === undefined) return;

    setBookmarksCount(data.bookmarks.length);
  }, [data]);

  const handleBookmarksClick = () => {
    navigate("/ulozene");
  };

  useEffect(() => {
    if (searchText === null || searchText.length < 3) {
      setFoundAssignments(null);
      removeAllQueryParams();
      return;
    }

    const timer = setTimeout(() => {
      setIsSearching(true);

      trigger({ search: searchText })
        .then(data => {
          const parsedAssignments: IAssignment[] = [];
          const idList: string[] = [];
          const allAssignments = data.data?.userAssignmentsFulltext.items;

          if (allAssignments === undefined) {
            setFoundAssignments([]);
            return;
          }

          for (const assignment of allAssignments) {
            if (assignment.assignment === undefined || assignment.assignment === null) continue;

            idList.push(assignment.id);

            parsedAssignments.push({
              id: assignment.id,
              text: assignment.assignment?.assignment?.assignmentText ?? "",
              href: getAssignmentHref(assignment.id),
              testId: assignment.assignment?.general?.admissionTest.name ?? null,
              testOrder: assignment.assignment?.general?.testOrder ?? null,
              envelopeType: assignment.envelope,
            });
          }

          dispatch(setTopicAssignments({ topicId: "searched", assignments: idList }));
          setFoundAssignments(parsedAssignments);
        })
        .catch(console.error)
        .finally(() => {
          setIsSearching(false);
        });
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);

  const submitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const handleSearch = (searched: string) => {
    setSearchText(searched);
    setEagerQueryParam(SEARCH_QUERY_PARAM, searched);
  };

  const handlePageChange = (page: number) => {
    setSearchParam(PAGE_QUERY_PARAM, page.toString());
  };

  return (
    <>
      <div className={"page-header page-header--has-tabs page-header--no-tabs-desktop"}>
        <Container className={"container-mw-md"}>
          <Breadcrumb>
            <li className={"breadcrumb-item"}>
              <Link to={"/"}>
                <IconArrowBack />
                {t("goHome")}
              </Link>
            </li>
          </Breadcrumb>

          <h1 className={"mb-25px"}>{t("pageTitle.tasksForExercise", { ns: "envelope" })}</h1>

          <Row className={"row--border-inside row--border-inside-sm-col mb-25px gx-4"}>
            <Col xs={12} sm={6} className={"col"}>
              <div className="page-header__info-block">
                <Image src={"/images/envelope-waiting.svg"} />
                <p>
                  {t("subjects.setsToComplete", { ns: "assignments", count: testsToUnlock ?? 0 })}{" "}
                  <Button
                    variant={"link"}
                    onClick={() => {
                      showModal(true);
                    }}
                    className="p-0 m-0 border-none vertical-align-reset"
                  >
                    {t("subjects.howItWorks", { ns: "assignments" })}
                  </Button>
                </p>
              </div>
            </Col>
            <Col xs={12} sm={6} className={"col"}>
              <div className="page-header__info-block">
                <Image src={"/images/envelope-buy.svg"} />
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("subjects.testsToBuy", { ns: "assignments", count: testsToBuy ?? 0 }),
                  }}
                ></p>
              </div>
            </Col>
          </Row>

          <Button variant="tertiary" className="btn-shadow btn-arrow" onClick={handleBookmarksClick}>
            <IconBookmark className={"icon me-2"} />
            {t("subjects.bookmarkedAssignments", { ns: "assignments", count: bookmarksCount ?? 0 })}
          </Button>

          <hr className={"my-25px"} />

          <Form onSubmit={submitForm}>
            <Form.Control
              className={"form-search"}
              placeholder={"Hledej v textu úlohy..."}
              value={searchText ?? ""}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                handleSearch(event.target.value);
              }}
            />
          </Form>
        </Container>
      </div>
      <Container
        className={`container-mw-md page-container pt-25px pt-sm-45px flex-grow-1 ${
          !isLoading && foundAssignments !== null && foundAssignments.length === 0 ? "d-grid" : ""
        }`}
      >
        {isLoading || isSearching ? (
          <Loading />
        ) : foundAssignments !== null ? (
          foundAssignments.length === 0 ? (
            <p className={"mx-5 my-auto mt-sm-3 mb-sm-0 text-center"}>{t("searchNotFound", { ns: "common" })}</p>
          ) : (
            <>
              {foundAssignments?.slice((page - 1) * 10, page * 10).map(assignment => {
                return <AssignmentBox key={assignment.href} assignment={assignment} />;
              })}

              {foundAssignments.length > 10 ? (
                <Pagination
                  className={"mt-16px"}
                  current={page}
                  total={Math.ceil(foundAssignments.length / 10)}
                  onNavigation={handlePageChange}
                />
              ) : null}
            </>
          )
        ) : data === undefined ? null : (
          <SubjectsTab
            data={sortGeneralSubjects(data, getTopicHref)}
            fallbackText={t("subjects.noTopics", { ns: "assignments" })}
            showStars={true}
          />
        )}
      </Container>

      <EnvelopeExplanationModal
        isShown={isModalShown}
        onHide={() => {
          showModal(false);
        }}
      />
    </>
  );
};

export default Subjects;
