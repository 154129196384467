import type { GetEnvelopeSubjectsTopicsQuery, GetSubjectsTopicsQuery } from "api/generated";

export interface IParsedTopic {
  topicName: string;
  topicPercentage: number | null;
  href: string;
  id: string;
  envelopes: {
    red: number | null;
    orange: number | null;
    green: number | null;
  };
}

export interface ISubjectData {
  subjectName: string;
  subjectId: string;
  subjectPercentage: number | null;
  assignmentsCount?: number;
  topics: IParsedTopic[];
}

export const sortGeneralSubjects = (
  subjectsData: GetSubjectsTopicsQuery,
  getTopicHref: (topicId: string) => string,
) => {
  if (subjectsData?.subjects === undefined) return [];

  const parsedData: ISubjectData[] = [];

  for (const subject of subjectsData?.subjects) {
    if (subject.isFavorite === true && typeof subject.appName === "string") {
      const userSubject = subjectsData.userSubjects.find(
        subjectPercentage => subjectPercentage.subject?.id === subject.id,
      );

      const topics = subject.superTopics
        .map(superTopic => parseSuperTopic(superTopic, getTopicHref, subjectsData.userSuperTopics))
        .filter((superTopic): superTopic is IParsedTopic => superTopic !== null)
        .sort((a, b) => a.topicName.localeCompare(b.topicName));

      parsedData.push({
        subjectName: subject.appName,
        subjectId: subject.id,
        subjectPercentage:
          userSubject !== undefined && "scoreAggregatePercent" in userSubject
            ? userSubject.scoreAggregatePercent ?? null
            : null,
        topics,
      });
    }
  }

  parsedData.sort((a, b) => a.subjectName.localeCompare(b.subjectName));

  return parsedData;
};

export const sortEnvelopeSubjects = (
  subjectsData: GetEnvelopeSubjectsTopicsQuery,
  getTopicHref: (topicId: string) => string,
  envelopeId?: string,
) => {
  if (subjectsData?.subjects === undefined) return [];

  const parsedData: ISubjectData[] = [];

  for (const subject of subjectsData?.subjects) {
    if (subject.isFavorite === true && typeof subject.appName === "string") {
      const userSubject = subjectsData.userSubjects.find(
        subjectPercentage => subjectPercentage.subject?.id === subject.id,
      );

      const topics = subject.superTopics
        .map(superTopic => {
          switch (envelopeId) {
            case "cervena":
              return parseSuperTopicRedEnvelope(superTopic, getTopicHref, subjectsData.userSuperTopics);
            case "oranzova":
              return parseSuperTopicOrangeEnvelope(superTopic, getTopicHref, subjectsData.userSuperTopics);
            case "zelena":
              return parseSuperTopicGreenEnvelope(superTopic, getTopicHref, subjectsData.userSuperTopics);
            default:
              return null;
          }
        })
        .filter((superTopic): superTopic is IParsedTopic => superTopic !== null)
        .sort((a, b) => a.topicName.localeCompare(b.topicName));

      parsedData.push({
        subjectName: subject.appName,
        subjectId: subject.id,
        subjectPercentage:
          userSubject !== undefined && "scoreAggregatePercent" in userSubject
            ? userSubject.scoreAggregatePercent ?? null
            : null,
        assignmentsCount:
          userSubject?.assignmentsGreen ?? userSubject?.assignmentsRed ?? userSubject?.assignmentsYellow,
        topics,
      });
    }
  }

  parsedData.sort((a, b) => a.subjectName.localeCompare(b.subjectName));

  return parsedData;
};

interface ISuperTopic {
  superTopic?:
    | {
        id: string;
      }
    | null
    | undefined;
}
interface ICombinedTopic {
  combinedTopic?:
    | {
        id: string;
      }
    | null
    | undefined;
}
interface ITopic {
  topic?:
    | {
        id: string;
      }
    | null
    | undefined;
}

interface IUserTopicBase {
  scoreAggregatePercent?: number | null;
  hasAssignments?: boolean;
}

interface IUserTopic extends IUserTopicBase {
  assignmentsRed: number | null;
  assignmentsYellow: number | null;
  assignmentsGreen: number | null;
}

interface IEnvelopeTopicRed {
  scoreAggregatePercent?: number | null;
  assignmentsRed?: number;
}

interface IEnvelopeTopicOrange {
  scoreAggregatePercent?: number | null;
  assignmentsYellow?: number;
}

interface IEnvelopeTopicGreen {
  scoreAggregatePercent?: number | null;
  assignmentsGreen?: number;
}

interface IGenericTopic {
  id: string;
  name?: string | null | undefined;
}

const parseSuperTopic = (
  topic: IGenericTopic,
  getTopicHref: (topicId: string) => string,
  userTopics?: Array<IUserTopicBase & ISuperTopic>,
): IParsedTopic | null => {
  if (userTopics === undefined) return null;

  const userTopic = userTopics.find(userTopic => userTopic.superTopic?.id === topic.id && userTopic.hasAssignments);

  if (userTopic === undefined) return null;

  return {
    id: topic.id,
    topicName: topic.name ?? "",
    topicPercentage: userTopic?.scoreAggregatePercent ?? null,
    href: getTopicHref(topic.id),
    envelopes: {
      red: null,
      orange: null,
      green: null,
    },
  };
};

const parseSuperTopicRedEnvelope = (
  topic: IGenericTopic,
  getTopicHref: (topicId: string) => string,
  userTopics?: Array<IEnvelopeTopicRed & ISuperTopic>,
): IParsedTopic | null => {
  if (userTopics === undefined) return null;

  const userTopic = userTopics.find(userTopic => userTopic.superTopic?.id === topic.id && userTopic.assignmentsRed);

  if (userTopic === undefined) return null;

  return {
    topicName: topic.name ?? "",
    topicPercentage: userTopic?.scoreAggregatePercent ?? null,
    href: getTopicHref(topic.id),
    id: topic.id,
    envelopes: {
      red: userTopic.assignmentsRed ?? null,
      orange: null,
      green: null,
    },
  };
};

const parseSuperTopicOrangeEnvelope = (
  topic: IGenericTopic,
  getTopicHref: (topicId: string) => string,
  userTopics?: Array<IEnvelopeTopicOrange & ISuperTopic>,
): IParsedTopic | null => {
  if (userTopics === undefined) return null;

  const userTopic = userTopics.find(
    userTopic =>
      userTopic.superTopic?.id === topic.id &&
      userTopic.assignmentsYellow !== undefined &&
      userTopic.assignmentsYellow > 0,
  );

  if (userTopic === undefined) return null;

  return {
    topicName: topic.name ?? "",
    topicPercentage: userTopic?.scoreAggregatePercent ?? null,
    href: getTopicHref(topic.id),
    id: topic.id,
    envelopes: {
      red: null,
      orange: userTopic.assignmentsYellow ?? null,
      green: null,
    },
  };
};

const parseSuperTopicGreenEnvelope = (
  topic: IGenericTopic,
  getTopicHref: (topicId: string) => string,
  userTopics?: Array<IEnvelopeTopicGreen & ISuperTopic>,
): IParsedTopic | null => {
  if (userTopics === undefined) return null;

  const userTopic = userTopics.find(
    userTopic =>
      userTopic.superTopic?.id === topic.id && userTopic.assignmentsGreen !== undefined && userTopic.assignmentsGreen,
  );

  if (userTopic === undefined) return null;

  return {
    topicName: topic.name ?? "",
    topicPercentage: userTopic?.scoreAggregatePercent ?? null,
    href: getTopicHref(topic.id),
    id: topic.id,
    envelopes: {
      red: null,
      orange: null,
      green: userTopic.assignmentsGreen ?? null,
    },
  };
};

export const parseCombinedTopic = (
  topic: IGenericTopic,
  userTopics: Array<IUserTopic & ICombinedTopic>,
  getTopicHref: (topicId: string) => string,
): IParsedTopic | null => {
  const userTopic = userTopics.find(userTopic => userTopic.combinedTopic?.id === topic.id && userTopic.hasAssignments);

  if (userTopic === undefined) return null;

  return {
    id: topic.id,
    topicName: topic.name ?? "",
    topicPercentage: userTopic?.scoreAggregatePercent ?? null,
    href: getTopicHref(topic.id),
    envelopes: {
      red: userTopic.assignmentsRed,
      orange: userTopic.assignmentsYellow,
      green: userTopic.assignmentsGreen,
    },
  };
};

export const parseCombinedTopicRedEnvelope = (
  topic: IGenericTopic,
  userTopics: Array<IEnvelopeTopicRed & ICombinedTopic>,
  getTopicHref: (topicId: string) => string,
): IParsedTopic | null => {
  const userTopic = userTopics.find(
    userTopic =>
      userTopic.combinedTopic?.id === topic.id &&
      userTopic.assignmentsRed !== undefined &&
      userTopic.assignmentsRed > 0,
  );

  if (userTopic === undefined) return null;

  return {
    id: topic.id,
    topicName: topic.name ?? "",
    topicPercentage: userTopic?.scoreAggregatePercent ?? null,
    href: getTopicHref(topic.id),
    envelopes: {
      red: userTopic.assignmentsRed ?? null,
      orange: null,
      green: null,
    },
  };
};

export const parseCombinedTopicOrangeEnvelope = (
  topic: IGenericTopic,
  userTopics: Array<IEnvelopeTopicOrange & ICombinedTopic>,
  getTopicHref: (topicId: string) => string,
): IParsedTopic | null => {
  const userTopic = userTopics.find(
    userTopic =>
      userTopic.combinedTopic?.id === topic.id &&
      userTopic.assignmentsYellow !== undefined &&
      userTopic.assignmentsYellow > 0,
  );

  if (userTopic === undefined) return null;

  return {
    id: topic.id,
    topicName: topic.name ?? "",
    topicPercentage: userTopic?.scoreAggregatePercent ?? null,
    href: getTopicHref(topic.id),
    envelopes: {
      red: null,
      orange: userTopic.assignmentsYellow ?? null,
      green: null,
    },
  };
};

export const parseCombinedTopicGreenEnvelope = (
  topic: IGenericTopic,
  userTopics: Array<IEnvelopeTopicGreen & ICombinedTopic>,
  getTopicHref: (topicId: string) => string,
): IParsedTopic | null => {
  const userTopic = userTopics.find(
    userTopic =>
      userTopic.combinedTopic?.id === topic.id &&
      userTopic.assignmentsGreen !== undefined &&
      userTopic.assignmentsGreen > 0,
  );

  if (userTopic === undefined) return null;

  return {
    id: topic.id,
    topicName: topic.name ?? "",
    topicPercentage: userTopic?.scoreAggregatePercent ?? null,
    href: getTopicHref(topic.id),
    envelopes: {
      red: null,
      orange: null,
      green: userTopic.assignmentsGreen ?? null,
    },
  };
};

export const parseTopic = (
  topic: IGenericTopic,
  userTopics: Array<IUserTopic & ITopic>,
  getTopicHref: (topicId: string) => string,
): IParsedTopic | null => {
  const userTopic = userTopics.find(userTopic => userTopic.topic?.id === topic.id && userTopic.hasAssignments);

  if (userTopic === undefined) return null;

  return {
    id: topic.id,
    topicName: topic.name ?? "",
    topicPercentage: userTopic?.scoreAggregatePercent ?? null,
    href: getTopicHref(topic.id),
    envelopes: {
      red: userTopic.assignmentsRed,
      orange: userTopic.assignmentsYellow,
      green: userTopic.assignmentsGreen,
    },
  };
};

interface TName {
  topicName?: string | null | undefined;
}

export const sortTopicsByName = <T extends TName>(topics: T[]) => {
  const sorted = [...topics];

  sorted.sort((a, b) => {
    if (typeof a.topicName === "string" && typeof b.topicName === "string") {
      return a.topicName.localeCompare(b.topicName);
    }

    if (typeof a.topicName === "string") return -1;
    if (typeof b.topicName === "string") return 1;
    return 0;
  });

  return sorted;
};
