import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "store/store";

interface IAssignmentNavigationState {
  topicAssignments: Record<string, string[]>;
}

const initialState: IAssignmentNavigationState = {
  topicAssignments: {},
};

const assignmentNavigationSlice = createSlice({
  name: "assignmentNavigation",
  initialState,
  reducers: {
    setTopicAssignments: (state, action: PayloadAction<{ topicId: string; assignments: string[] }>) => {
      state.topicAssignments = {
        ...state.topicAssignments,
        [action.payload.topicId]: action.payload.assignments,
      };
    },
  },
});

export default assignmentNavigationSlice.reducer;
export const { setTopicAssignments } = assignmentNavigationSlice.actions;

export const getTopicAssignments = (topicId: string) => (state: RootState) =>
  state.assignmentsNavigation.topicAssignments[topicId];
