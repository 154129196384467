import type { IExercise } from "store/slices/exercise";

import { saveToTimedLocalStorage, getFromTimedLocalStorage } from "utils/localStorage";

import { SESSION_STORAGE_KEY } from "const";

export interface ISessionSavedInStorage extends IExercise {
  spentTime: number;
}

export const saveSessionToStorage = (data: ISessionSavedInStorage) => {
  const expiration = new Date();
  expiration.setDate(expiration.getDate() + 1);
  expiration.setHours(2, 0, 0, 0);

  saveToTimedLocalStorage(SESSION_STORAGE_KEY, JSON.stringify(data), expiration.getTime());
};

export const getSessionFromStorage = () => {
  const dataString = getFromTimedLocalStorage(SESSION_STORAGE_KEY);

  if (dataString === null) return null;

  return JSON.parse(dataString) as ISessionSavedInStorage;
};

export const removeSessionFromStorage = () => {
  window.localStorage.removeItem(SESSION_STORAGE_KEY);
};
